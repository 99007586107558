var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-popup",
    {
      ref: "vspopup-per",
      attrs: {
        title: _vm.$t("plan.limit_types"),
        active: _vm.visible,
        id: "popup-entity",
      },
      on: {
        "update:active": function ($event) {
          _vm.visible = $event
        },
        close: _vm.closePopup,
      },
    },
    [
      _c("Grid", {
        ref: "grid-popup",
        attrs: {
          multipleSelect: true,
          service: _vm.service,
          route_name: "plans",
          route_grid_path: "/plan_limit_types",
          hide_actions: "true",
          order_column: "name",
          selectObject: "true",
          hide_searchbar: "true",
          perPage: "25",
          "request-params": _vm.filtersGrid,
          column_formats: {
            name: (val) => _vm.$t(`plan.limit.${val}`),
          },
        },
        on: { changedSelection: _vm.changedSelection },
      }),
      _c(
        "div",
        { staticClass: "flex flex-row justify-end gap-4" },
        [
          _c("vs-button", { on: { click: _vm.add } }, [
            _vm._v(" " + _vm._s(_vm.$t("adicionar")) + " "),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }